<template>
  <div
    :style="{
      backgroundColor: computedBackgroundColor,
    }"
    class="cms-element-cr-extended-text rich-text h-full"
  >
    <CmsTextRender />
  </div>
</template>

<script setup lang="ts">
import { useCmsElementConfig } from "#imports";
import { useFixedUrlResolver } from "@/composables/useFixedUrlResolver";
import { computed, getCurrentInstance, h } from "vue";
import { decodeHTML } from "entities";
import { getOptionsFromNode } from "@/utils/html-to-vue/getOptionsFromNode";
import type { NodeObject } from "@/utils/html-to-vue/getOptionsFromNode";
import { renderHtml } from "@/utils/html-to-vue/renderToHtml";
import type { CmsElement } from "@/types/cms";
import { useInternalLinkMapper } from "@/composables/useInternalLinkMapper";
import { useActiveCategoryDetailStore } from "@/stores/activeCategoryDetail";

const props = defineProps<{
  content: CmsElement<{
    backgroundColor: string;
    content: string;
  }>;
}>();

const { mapHTMLWithLinks } = useInternalLinkMapper();

const { activeCategoryDetail } = toRefs(useActiveCategoryDetailStore());

const computedBackgroundColor = computed(() => {
  return props.content.config.backgroundColor?.value;
});

const context = getCurrentInstance();

const { getConfigValue } = useCmsElementConfig(props.content);

const mappedContent = computed<string>(() => {
  const contentValue =
    props.content?.data?.content || getConfigValue("content");
  if (!activeCategoryDetail.value) return contentValue;

  const mapFieldRegex = /{{\s*category\.(\w+)\s*}}/g;

  let matches;
  let modifiedContent = contentValue;
  while ((matches = mapFieldRegex.exec(contentValue))) {
    let snippet = `{{ category.${matches[1]} }}`;
    if (
      activeCategoryDetail.value?.[matches[1]] !== undefined &&
      activeCategoryDetail.value?.[matches[1]] !== null
    ) {
      modifiedContent = modifiedContent.replace(
        snippet,
        activeCategoryDetail.value[matches[1]],
      );
    }
  }

  return modifiedContent;
});

const { resolveUrl } = useFixedUrlResolver();

const updatedTextWithCorrectInternalLinks = await mapHTMLWithLinks(
  mappedContent.value,
);

const CmsTextRender = () => {
  const config = {
    textTransformer: (text: string) => decodeHTML(text),
    extraComponentsMap: {
      link: {
        conditions(node: NodeObject) {
          return (
            node.type === "tag" &&
            node.name === "a" &&
            !node.attrs?.class?.match(/btn\s?/)
          );
        },
        renderer(node: any, children: any, createElement: any) {
          return createElement(
            "a",
            {
              class:
                "underline text-base font-normal text-primary hover:text-secondary-900",
              ...getOptionsFromNode(node, resolveUrl).attrs,
            },
            [...children],
          );
        },
      },
      button: {
        conditions(node: NodeObject) {
          return (
            node.type === "tag" &&
            node.name === "a" &&
            node.attrs?.class?.match(/btn\s?/)
          );
        },
        renderer(node: NodeObject, children: any, createElement: any) {
          let _class = "";
          if (!node?.attrs?.class) {
            _class = node?.class;
          } else {
            _class = node?.attrs?.class;
          }

          return createElement(
            "a",
            {
              class: _class,
              ...getOptionsFromNode(node, resolveUrl).attrs,
            },
            [...children],
          );
        },
      },
      font: {
        conditions(node: NodeObject) {
          return node.type === "tag" && node.name === "font";
        },
        renderer(node: NodeObject, children: any, createElement: any) {
          // convert from <font color="#ce0000">Headline 1</font> to <span style="color:#ce0000">Headline 1</span>
          let newStyle = null;
          const styleColor = node?.attrs?.color;
          if (styleColor) {
            const currentStyle = node.attrs?.style ?? "";
            newStyle = `color:${styleColor};${currentStyle}`;
            delete node.attrs?.color;
          }

          return createElement(
            "span",
            {
              style: newStyle,
              ...getOptionsFromNode(node, resolveUrl).attrs,
            },
            [...children],
          );
        },
      },
      img: {
        conditions(node: NodeObject) {
          return node.type === "tag" && node.name === "img";
        },
        renderer(node: any, children: any, createElement: any) {
          return createElement(
            "img",
            getOptionsFromNode(node, resolveUrl)?.attrs,
          );
        },
      },
    },
  };
  const rawHtml =
    updatedTextWithCorrectInternalLinks?.length > 0
      ? updatedTextWithCorrectInternalLinks
      : "<div class='cms-element-text missing-content-element'></div>";

  return renderHtml(rawHtml, config, h, context, resolveUrl);
};
</script>

<style>
.rich-text p:has(a) {
  margin-bottom: 0;
}
</style>
